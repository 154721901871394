import React, { useEffect, useState } from "react";
import { Button, Dropdown, Space, Input, Row, Col, Popover, Card, Tooltip, Select, DatePicker, Spin, Typography } from "antd";
import { CloseOutlined, DownloadOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
// Components
import DataTableComponent from "../DataTableComponent";
import InfiniteScroll from 'react-infinite-scroll-component';
import "./../Order/ordersTable.css";
import routes from "../../helpers/routes";
import { Images } from "../../images";
// Actions
import { actions as transactionAction } from "../../contexts/transaction/actions";
import { actions as marketplaceActions } from "../../contexts/marketplace/actions";
// Dispatch & States
import { useTransactionDispatch, useTransactionState } from "../../contexts/transaction";
import { useMarketplaceDispatch, } from "../../contexts/marketplace";
// Utils & Constants
import {
  STRATS_CONVERSION, TRANSACTION_STATUS, TRANSACTION_STATUS_CLASSES, TRANSACTION_STATUS_COLOR,
  DOWNLOAD_OPTIONS, REDEMPTION_STATUS, REDEMPTION_STATUS_CLASSES, US_DATE_FORMAT,
  DATE_TIME_FORMAT
} from "../../helpers/constants";
import { SEO } from "../../helpers/seoConstant";
import { getAgoTime, getStringDate } from "../../helpers/utils";
import { TRANSACTION_FILTER } from "../Order/constant";
import { useCategoryState } from "../../contexts/category";
import GlobalTransactionResponsive from "./GlobalTransactionResponsive";

const { Title } = Typography;

const GlobalTransaction = ({ user }) => {
  const StratsIcon = <img src={Images.strats} alt="STRATS" className="mx-1 w-5 h-5" />
  // Dispatch
  const transactionDispatch = useTransactionDispatch();
  const marketplaceDispatch = useMarketplaceDispatch();
  // States
  const { globalTransactions, isTransactionLoading, count } = useTransactionState();
  const { categorys } = useCategoryState();

  const navigate = useNavigate();
  // const location = useLocation();

  // const currentMonth = dayjs().startOf('month').unix();
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [type, setType] = useState("");
  const [list, setList] = useState([]);
  const [dateQuery, setDateQuery] = useState("");
  const [transactions, setTransactions] = useState(globalTransactions)
  const [originAddress, setOriginAddress] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(['Order', 'Transfer', 'Redemption']);
  const [isFilterActive, setIsFilterActive] = useState(false)
  const formatter = new Intl.NumberFormat('en-US');
  const formattedNum = (num) => formatter.format(num);

  useEffect(() => {
    async function fetchStratsAddress() {
      const stratsAddress = await marketplaceActions.fetchStratsAddress(marketplaceDispatch);
      await marketplaceActions.fetchStratsBalance(marketplaceDispatch);
      setOriginAddress(stratsAddress);
    }
    fetchStratsAddress();
  }, [marketplaceDispatch]);

  useEffect(() => {
    transactionAction.fetchGlobalTransaction(
      transactionDispatch,
      limit,
      offset,
      selectedFilters
    )
  }, [dateQuery, limit, offset, selectedFilters])
  

  useEffect(() => {
    let filteredData = globalTransactions;
    setList((prev)=>[...prev, ...globalTransactions])
    setTransactions(filteredData);
  }, [globalTransactions]);

  const Content = ({ data }) => {
    const price = data?.assetPrice || data?.price
    const quantityIsDecimal = data?.quantityIsDecimal
    return <div className="min-h-44 h-full" style={{ width: '460px' }}>
      <Card>
        <Row>
          <Col span={6}>
            <img src={data?.assetImage} alt={data?.assetName} className="border w-88 h-88 border-indigo-600 rounded-md" />
          </Col>
          <Col span={8} offset={1}>
            <p className="text-base font-bold text-truncate cursor-pointer" onClick={() => { handleAssetRedirection(data) }}>{data?.assetName}</p>
            <p style={{ color: '#827474' }} className="font-medium mt-2 min-h-20 cursor-default text-truncate"><Tooltip placement="top" title={data.assetDescription.replace(/<\/?[^>]+(>|$)/g, "")}> {data?.assetDescription.replace(/<\/?[^>]+(>|$)/g, "")} </Tooltip></p>
          </Col>
          <Col span={8} offset={1}>
            {price
              ? <p className="text-right flex justify-end items-center"> <b>$ {quantityIsDecimal === "True" ? (price * 100) : price} </b> &nbsp;(<span className="text-[#13188A] font-bold"> {(quantityIsDecimal === "True" ? (price * 100) : price) * STRATS_CONVERSION} </span>{StratsIcon}) </p>
              : <p className="text-right text-[#13188A] font-bold text-sm"> No Price Available  </p>}
          </Col>
        </Row>
      </Card>
    </div>
  };

  const handleAssetRedirection = (data) => {
    const url = routes.MarketplaceProductDetail.url.replace(':address', data.assetAddress).replace(':name', data.assetName)
    navigate(url)
  }

  const column = [
    {
      title: <p className="text-center font-bold">Type</p>,
      dataIndex: "type",
      key: "type",
      width: "150px",
      render: (text) => (<p
        style={{ background: TRANSACTION_STATUS_COLOR[text] }} 
        className={`
        bg-${TRANSACTION_STATUS_COLOR[text]} 
        min-w-[80px] text-center cursor-default px-2 py-2 rounded-lg text-white`}>{text}</p>),
    },
    {
      title: "Asset",
      dataIndex: "Item",
      key: "Item",
      align: "left",
      width: '150px',
      render: (asset, data) => (
        <Popover className="flex" content={<Content data={data} />} trigger="hover">
          <div className="flex items-center cursor-default">
            <img src={data?.assetImage} alt={data?.assetName} width={24} height={30}
              className="border w-9 h-9 border-indigo-600 rounded-md object-contain"
            />
            <span className="ml-1 text-truncate">{data?.assetName}</span>
          </div>
        </Popover>
      )
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: '100px',
      render: (data, { quantity, quantityIsDecimal }) => <span>{quantity ? parseInt(quantity) : '--'}</span>
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: '100px',
      render: (data, { price, quantityIsDecimal }) =>
        <>
          <p className="text-base flex justify-end items-center">{price ? (formattedNum(quantityIsDecimal && quantityIsDecimal === "True" ? (price * 100) : price) * 100).toFixed(0) : '--'} <span>{price && StratsIcon}</span></p>
          <p className="text-xs">{price ? `${formattedNum(quantityIsDecimal && quantityIsDecimal === "True" ? (price * 100) : price)} $` : '--'}</p>
        </>
    },
    {
      title: "Buyer/Sender",
      dataIndex: "from",
      key: "from",
      align: "center",
      width: '150px',
    },
    {
      title: "Seller/Recipient",
      dataIndex: "to",
      key: "to",
      align: "center",
      width: '150px',
    },
    {
      dataIndex: "date",
      key: "date",
      width: '150px',
      render: (text, { createdDate }) => <p>{getStringDate(createdDate, DATE_TIME_FORMAT)}</p>,
      title: (
        <div style={{ display: "flex" }}>
          <div>{"Date"}</div>
        </div>
      ),
    },
  ];

  const metaImg = SEO.IMAGE_META;

  const handleFilter = (value) => {
    setList([]);
    setOffset(0);
    setSelectedFilters((prev) => {
      if (prev.includes(value)) {
        const arr = prev.filter((item) => item !== value)
        return arr;
      } else {
        const arr = [...prev, value];
        return arr;
      }
    });
  };

  const bgColor = (item) => {
    return selectedFilters.includes(item) ? 'bg-[#8388D2]' : 'bg-[#F6F6F6]';
  }

  const handleFilterActive = () => {
    setIsFilterActive((prev) => !prev)
  }

  const FilterComponent = () => {
    return <Card>
      <Title level={5} className="mt-2">
        Transaction Types
      </Title>
      <div className="flex flex-wrap">
        {TRANSACTION_FILTER.slice(1, 4)?.map(({ label }) => {
          return <span onClick={() => { handleFilter(label) }} className={`border-lg p-2 m-2 rounded-lg ${bgColor(label)} cursor-pointer`} key={label}> {label} </span>
        })}
      </div>
    </Card>
  }

 const handleClearFilter = () =>{
    setSelectedFilters([]);
    setOffset(0);
    setList([]);
 }

  const SelectedFilter = () => {
    return selectedFilters?.length !== 0 && <div className="h-auto w-full p-2 flex flex-wrap"> {selectedFilters?.map((item) =>
      <span onClick={() => { handleFilter(item) }} className="p-2 m-2 rounded-lg bg-[#F6F6F6] cursor-pointer" key={item}> {item} <span className="font-semibold"><CloseOutlined /></span> </span>)}
      <span onClick={handleClearFilter} className="p-2 m-2 rounded-lg bg-[#13188A] cursor-pointer text-white" > Clear All </span>
    </div>
  }

  const fetchData = () => {
    setOffset((prev)=> prev + 15)
  }

  return (
    <Row>
      <Col span={24} className="w-full xs:max-h-[60px] py-4 px-4 md:min-h-[96px] bg-[#F6F6F6] flex flex-col md:flex-row lg:px-14 justify-between items-center mt-6 lg:mt-8">
        <Row className="w-full flex justify-between items-center">
          <Col xs={24} lg={4} className="flex justify-between w-full">
            <Button className="!px-1 md:!px-0 md:ml-5 lg:ml-0  flex items-center flex-row-reverse gap-[6px] text-lg md:text-2xl font-semibold !text-[#13188A] "
              type="link"
              icon={<img src={Images.ForwardIcon}
                alt={metaImg}
                title={metaImg}
                className="hidden md:block w-6 h-6" />}> Global Transactions
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={22} className="mx-auto mt-5 ">
        <div className="w-full flex md:hidden order_responsive">
            <Row className="w-full">
              <Col>
                <SelectedFilter />
              </Col>
              <Col span={24}>
                <div className="w-full flex justify-between items-center">
                  <Title level={3} className="mt-2">
                    Filter
                  </Title>
                  <Button type="primary" shape="round" onClick={handleFilterActive} icon={isFilterActive ? <CloseOutlined /> : <FilterOutlined />} size={'large'} />
                </div>
                {isFilterActive &&
                  <FilterComponent />}
              </Col>
              <GlobalTransactionResponsive data={list} user={user} isTransactionLoading={isTransactionLoading}  />
            </Row>
        </div>
        <div className="hidden md:block">
          <Row>
            <Col span={5}>
              <Title level={3} className="mt-2">
                Filter
              </Title>
              <FilterComponent />
            </Col>
            <Col span={18} offset={1}>
              <SelectedFilter />
              <div
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={list.length}
                  next={fetchData}
                  hasMore={true}
                  loader={isTransactionLoading && <h3 className="text-center"><Spin/></h3>}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  <DataTableComponent
                    columns={column}
                    data={list}
                    isLoading={false}
                    pagination={false}
                    scrollX="100%"
                  />
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default GlobalTransaction;
